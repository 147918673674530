import React, { useRef, useState, useEffect, useMemo } from "react";
import Modal from "react-modal";
import * as SmoothScrolling from "smoothscroll-polyfill";

import Header from "./Components/Header";
import Content from "./Components/Content";
import ModalContent from "./Components/ModalContent";
import { useToggle } from "./Hooks/useToggle";
import "./App.css";

SmoothScrolling.polyfill();

function App() {
  const [isMobile, setIsMobile] = useState<boolean>(false);
  const [isWeb, setIsWeb] = useState<boolean>(true);
  const [isPromptClosed, setIsPromptClosed] = useState<boolean>(true);
  const [current, setCurrent] = useState({
    stepper: 2,
    left: window.screen.width / 3,
  });
  const [letGo, setLetGo] = useState<boolean>(false);
  const { state: isOpen, on: openModal, off: closeModal } = useToggle();
  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (
      /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|Windows Phone/i.test(
        navigator.userAgent
      )
    ) {
      containerRef.current?.scrollTo({
        left: (containerRef.current?.scrollWidth ?? 0) / 3
      });
      setIsMobile(true);
      setIsPromptClosed(false);
      setIsWeb(false);
      setLetGo(false);
      openModal();
    } else {
      setIsMobile(false);
      setIsWeb(true);
      setLetGo(false);
    }
  }, [openModal]);

  const handleLetGo = React.useCallback(() => {
    setLetGo(true);
    setIsPromptClosed(true);
    closeModal();
  }, [closeModal]);

  let MAX_WIDTH = 0;
  let deltaX = 0;
  let deltaY = 0;

  const onTouchStart: React.TouchEventHandler<HTMLDivElement> = (e) => {
    MAX_WIDTH = e.currentTarget.scrollWidth;

    deltaX = e.touches[0].clientX;
    deltaY = e.touches[0].clientY;
  };

  const onTouchEnd: React.TouchEventHandler<HTMLDivElement> = (e) => {
    if (!isPromptClosed) return;

    let STEPPER = current.stepper;
    deltaX = deltaX - e.changedTouches[0].clientX;
    deltaY = deltaY - e.changedTouches[0].clientY;

    if (deltaX > 50 && STEPPER < 3) {
      STEPPER++;
    } else if (deltaX < -50 && STEPPER > 1) {
      STEPPER--;
    }

    deltaX = 0;
    deltaY = 0;
    let left = 0;

    if (STEPPER === 1) left = 0;
    else if (STEPPER === 2) left = MAX_WIDTH / 3;
    else left = MAX_WIDTH - MAX_WIDTH / 3;

    containerRef.current?.scrollTo({
      left,
      behavior: "smooth",
    });
    setCurrent({ stepper: STEPPER, left });
  };

  const header = useMemo(
    () => (
      <Header openModal={openModal} current={current} isMobile={isMobile} />
    ),
    [current, isMobile, openModal]
  );

  const content = useMemo(() => {
    let button_type = 0;
    const CURRENT_TIME = new Date().getTime();

    // NOTE: Javascript Date month starts from 0, don't ask me why
    // 7th Nov 12:00pm SGT (7th Nov 04:00am UTC)
    const TIME_STATE_1 = new Date(Date.UTC(2021, 10, 7, 4, 0, 0, 0)).getTime();
    // 15th Nov 12:00am SGT (14th Nov 04:00pm UTC)
    const TIME_STATE_2 = new Date(Date.UTC(2021, 10, 14, 16, 0, 0, 0)).getTime();

    if (CURRENT_TIME < TIME_STATE_1) {
      button_type = 1;
    } else if (CURRENT_TIME >= TIME_STATE_1 && CURRENT_TIME < TIME_STATE_2) {
      button_type = 2;
    } else {
      button_type = 3;
    }

    return (
      <Content
        stepper={current.stepper}
        isMobile={isMobile}
        isPromptClosed={isPromptClosed}
        letGo={letGo}
        buttonType={button_type}
      />
    );
  }, [current, isMobile, isPromptClosed, letGo]);

  return (
    <div
      ref={containerRef}
      className="App"
      onTouchStart={onTouchStart}
      onTouchEnd={onTouchEnd}
      id="AppElement"
    >
      <div className="App-content">
        {header}
        {content}
      </div>
      <Modal
        isOpen={isOpen}
        onRequestClose={() => {
          if (isMobile && letGo) {
            closeModal();
          } else if (isWeb) {
            closeModal();
          }
        }}
        className="App-modal"
        overlayClassName="App-overlay"
      >
        <ModalContent
          isMobile={isMobile}
          letGo={letGo}
          handleLetGo={handleLetGo}
        />
      </Modal>
    </div>
  );
}

export default App;
