export const Assets = {
    get discord() {
        return require('./icons/discord@3x.png').default;
    },
    get twitter() {
        return require('./icons/twitter@3x.png').default;
    },
    get gestureSwipe() {
        return require('./icons/gesture@3x.png').default;
    },
    get oneOfOne() {
        return require('./icons/1of1@3x.png').default;
    },
    get oneMillion() {
        return require('./icons/1million@3x.png').default;
    },
    get ntfGuarantee() {
        return require('./icons/nft_guarantee@3x.png').default;
    },
    get dogeCharacter() {
        return require('./doge.png').default;
    }
} 