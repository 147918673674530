interface ButtonProps
  extends React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLDivElement>,
    HTMLDivElement
  > {
  onPress: () => void;
  label: string;
  visible: boolean;
}

const Button = ({ onPress, label, visible, ...props }: ButtonProps): JSX.Element => (
  <div className="App-button" onClick={onPress} {...props} style={{visibility: visible ? 'visible' : 'hidden'}}>
    <p>{label}</p>
  </div>
);

Button.defaultProps = {
  onPress: () => {},
  label: "Coming much soon",
  visible: true
};

export default Button;
