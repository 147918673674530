import Button from "./Button";
import { Assets } from "../Assets";
import { modalContent } from "../Constants";

interface ModalContentProps {
  isMobile: boolean;
  letGo: boolean;
  handleLetGo: () => void;
}

const ModalContent = ({
  isMobile,
  letGo,
  handleLetGo,
}: ModalContentProps): JSX.Element => {
  const {
    heading1,
    heading2,
    paragraph1,
    paragraph2,
    paragraph3,
    paragraph4,
    paragraph5,
  } = modalContent;
  return (
    <>
    <div
      className={
        isMobile && !letGo
          ? "App-modal-content App-modal-content-gesture-change"
          : "App-modal-content"
      }
    >
      {((isMobile && letGo) || !isMobile) ? (
        <>
        <div className="App-modal-close">
            <div style={{cursor: 'pointer'}} onClick={handleLetGo}>X</div>
        </div>
        <div className="App-modal-content-tutorials">
          {/*<p className="App-modal-content-title">{heading1}</p>
          <p className="App-modal-content-title App-modal-content-padding">
            {heading2}
          </p>*/}
          <p className="App-modal-content-description" style={{paddingTop: 0}}>{paragraph1}</p>
          <p className="App-modal-content-description">{paragraph2}</p>
          <p className="App-modal-content-description">
            {paragraph3}
            <br />
            {paragraph4}
          </p>
          <p className="App-modal-content-description-extra">{paragraph5}</p>
        </div>
        </>
      ) : (
        <div className="App-modal-content-gesture">
          <div className="App-modal-gesture-container">
            <img src={Assets.gestureSwipe} alt="Gesture Swipe" style={{maxWidth: "50vw", height: "auto"}} />
          </div>
          <Button onPress={handleLetGo} label="Let’s go!" />
        </div>
      )}
    </div>
    </>
  );
};

export default ModalContent;
