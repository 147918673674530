import Button from "./Button";
import { Assets } from "../Assets";

interface ContentProps {
  isMobile: boolean;
  isPromptClosed: boolean;
  letGo: boolean;
  stepper: number;
  buttonType: number;
}

const Content = ({
  isMobile,
  isPromptClosed,
  letGo,
  stepper,
  buttonType,
}: ContentProps): JSX.Element => {
  let buttonData = {
    label: "Coming much soon",
    url: "",
  };
  switch (buttonType) {
    case 1:
      buttonData.label = "Coming much soon";
      buttonData.url = "https://opensea.io/assets/0x495f947276749ce646f68ac8c248420045cb7b5e/38951374966718806379611166908089937098471440267503199372934299807885861847041";
      break;
    case 2:
      buttonData.label = "Bid now such wow";
      buttonData.url = "https://opensea.io/assets/0x495f947276749ce646f68ac8c248420045cb7b5e/38951374966718806379611166908089937098471440267503199372934299807885861847041";
      break;
    case 3:
      buttonData.label = "Oh noes very sold";
      buttonData.url = "https://opensea.io/assets/0x495f947276749ce646f68ac8c248420045cb7b5e/38951374966718806379611166908089937098471440267503199372934299807885861847041";
      break;
  }

  return (
    <>
      <img
        src={Assets.dogeCharacter}
        className="App-toy-product"
        alt="Doge Character"
      />
      <div
        className={
          (stepper !== 1 && letGo && isMobile) || (stepper === 2 && isMobile)
            ? "App-nft-guarantee"
            : "App-nft-guarantee"
        }
      >
        <img src={Assets.ntfGuarantee} alt="NFT Guarantee" />
      </div>
      <div
        className={
          (stepper !== 1 && letGo && isMobile) || (stepper === 2 && isMobile)
            ? "App-one-for-one"
            : "App-one-for-one"
        }
      >
        <img src={Assets.oneOfOne} alt="One of One" />
      </div>
      <div
        className={
          (stepper !== 3 && letGo && isMobile) || (stepper === 2 && isMobile)
            ? "App-million-doge"
            : "App-million-doge"
        }
      >
        <img src={Assets.oneMillion} alt="One Million" />
      </div>
      <Button
        label={buttonData.label}
        visible={isPromptClosed}
        onPress={() => {
          if (buttonData.url.length > 0) window.location.href = buttonData.url;
        }}
      />
    </>
  );
};

export default Content;
