export const modalContent = {
  heading1:
    "Exclusive 1-of-1 edition 12” “Doge To The Moon” premium collectible",
  heading2: "Price: 1 million DOGE (equivalent, to be paid in ETH)",
  paragraph1: `
    To the mooooonnn! Will you join Doge on this wild ride to the moon? 
    Blast off into space with Doge and rocket upwards into meme-dom, 
    where value is wow and Doge is king. It’s Doge like you’ve never seen before. 
    Released in partnership with Coinhako, this exclusive super limited edition NFT 
    is the only one of its kind in the world and it could be yours from 500k DOGE*.
  `,
  paragraph2: `
    Mighty Jaxx will send the owner of the NFT an exclusive 1 of 1 edition 12” 
    Doge to the Moon: Boss Edition premium collectible, currently on display at the
    Right Click + Save NFT exhibition at Le Freeport Singapore. In addition,
    the owner of this product will receive a guaranteed Ultra-Rare NFT from
    the upcoming** generative Doge collection.
  `,
  paragraph3: `
    * Bids start at 500k DOGE. Buy it now price is 1 million Doge. Equivalent amount to be paid in ETH. 
  `,
  paragraph4: ` ** Upon announcement of the upcoming Doges NFT release, 
  the owner will see the option to mint their ultra-rare NFT on the Mighty Jaxx app.`,
  paragraph5: `
    Mighty Jaxx International Pte Ltd and “Doge to the Moon” are not
    affiliated, associated, authorized, endorsed by, or in any way
    officially connected with any cryptocurrency, including Dogecoin,
    Rocketdoge Coin, or Shiba Inu, or any of their affiliates or related
    business entities.
  `,
};
