import { Assets } from "../Assets";

interface HeaderProps {
  openModal: () => void;
  current: {
    stepper: number;
    left: number;
  };
  isMobile: boolean;
}

const Header = ({ openModal, current, isMobile }: HeaderProps) => (
  <div
    className="App-header"
    style={{
      width: isMobile ? window.screen.width : "100%",
      position: isMobile ? "fixed" : undefined,
      //paddingLeft: isMobile ? current.left : undefined,
    }}
  >
    <div className="App-header-left-box">
      <div className="App-header-left-button" onClick={openModal}>
        <p>Info</p>
      </div>
    </div>
    <div className="App-header-right-box">
      <a
        target="_blank"
        rel="noopener noreferrer"
        href="https://discord.com/invite/geWWETcFjd"
      >
        <img src={Assets.discord} alt="Discord" />
      </a>
      <a
        target="_blank"
        rel="noopener noreferrer"
        href="https://twitter.com/mighty_meta"
      >
        <img src={Assets.twitter} alt="Twitter" />
      </a>
    </div>
  </div>
);

export default Header;
